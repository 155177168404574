// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appClient/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/closing?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount,
      averageRevenue: response?.data?.averageRevenue,
      averageExpenses: response?.data?.averageExpenses,
      totalCount: response?.data?.totalCount
    }
  }
)

export const getData = createAsyncThunk('appClosing/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appClosing/getUser', async (id) => {
  const response = await axios.get(`${BASE_URL}/closing/${id}`)
  return response.data
})

export const getInvoices = createAsyncThunk('appClosing/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-project/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appClosing/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payment-by-closing/${id}`)
  return response.data
})
export const getExpense = createAsyncThunk('appClosing/getExpense', async (id) => {
  const response = await axios.get(`${BASE_URL}/expense/expense-by-closing/${id}`)
  return response.data
})
export const getExpenseDraft = createAsyncThunk('appClosing/getExpenseDraft', async () => {
  const response = await axios.get(`${BASE_URL}/expense/draft`)
  return response.data
})
export const getBonus = createAsyncThunk('appClosing/getBonus', async (id) => {
  const response = await axios.get(`${BASE_URL}/bonus/bonuses-by-closing/${id}`)
  return response.data
})
export const getStaffs = createAsyncThunk('appClosing/getStaffs', async (id) => {
  const response = await axios.get(`${BASE_URL}/working-hours/by-project/${id}`)
  return response.data
})
export const getCommissions = createAsyncThunk('appClosing/getCommissions', async (id) => {
  const response = await axios.get(`${BASE_URL}/commission/by-closing/${id}`)
  return response.data
})
export const getAllStaffs = createAsyncThunk('appClosing/getAllStaffs', async () => {
  const response = await axios.get(`${BASE_URL}/staff/`)
  return response.data.data
})
export const getStaffsByProject = createAsyncThunk('appClosing/getStaffsByProject', async (id) => {
  const response = await axios.get(`${BASE_URL}/staff/by-project/${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appClosing/addUser', async (payload) => {
  let data
  try {
    data = await axios.post(`${BASE_URL}/closing/${payload.id}`, payload.data)
    return data.data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})

export const updateUser = createAsyncThunk('appClosing/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/client/${user.id}`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const appClosingSlice = createSlice({
  name: 'appClosing',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: { payments: [], expenses: [], bonuses: [] },
    allStaffs: [],
    averageRevenue: 0,
    averageExpenses: 0,
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload?.data
        state.total = action?.payload?.totalPages
        state.averageRevenue = action?.payload?.averageRevenue
        state.averageExpenses = action?.payload?.averageExpenses
        state.totalCount = action?.payload?.totalCount
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getBonus.fulfilled, (state, action) => {
        state.selectedUser.bonuses = action.payload
      })
      .addCase(getExpense.fulfilled, (state, action) => {
        state.selectedUser.expenses = action.payload
      })
      .addCase(getExpenseDraft.fulfilled, (state, action) => {
        state.selectedUser.draftExpenses = action.payload
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.selectedUser.loggedHours = action.payload
      })
      .addCase(getCommissions.fulfilled, (state, action) => {
        state.selectedUser.commissions = action.payload
      })
      .addCase(getAllStaffs.fulfilled, (state, action) => {
        state.allStaffs = action.payload
      })
  }
})

export default appClosingSlice.reducer
