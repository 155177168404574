// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAds = createAsyncThunk('appMarketing/getAds', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/ad${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})

export const getLeads = createAsyncThunk('appMarketing/getLeads', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/lead?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const getOtherLinks = createAsyncThunk('appMarketing/getOtherLinks', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/other-link?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const getBDTemplates = createAsyncThunk(
  'appMarketing/getBDTemplates',
  async ({ search }) => {
    const response = await axios.get(
      `${BASE_URL}/marketing/bd-template?${search ? `&${search}` : ''}`
    )
    return { data: response.data.data, total: response.data.pageCount }
  }
)
export const getBlogs = createAsyncThunk('appMarketing/getBlogs', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/blog?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const getUIUX = createAsyncThunk('appMarketing/getUIUX', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/uiux?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const getProjectTechnologies = createAsyncThunk(
  'appMarketing/getProjectTechnologies',
  async ({ search }) => {
    const response = await axios.get(
      `${BASE_URL}/marketing/project-technology?${search ? `&${search}` : ''}`
    )
    return { data: response.data.data, total: response.data.pageCount }
  }
)
export const getEvents = createAsyncThunk('appMarketing/getEvents', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/event?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const getFreelanceProfiles = createAsyncThunk(
  'appMarketing/getFreelanceProfiles',
  async ({ search }) => {
    const response = await axios.get(
      `${BASE_URL}/marketing/freelance-profile?${search ? `&${search}` : ''}`
    )
    return { data: response.data.data, total: response.data.pageCount }
  }
)
export const getPortfolioProfiles = createAsyncThunk(
  'appMarketing/getPortfolioProfiles',
  async ({ search }) => {
    const response = await axios.get(
      `${BASE_URL}/marketing/portfolio-profile?${search ? `&${search}` : ''}`
    )
    return { data: response.data.data, total: response.data.pageCount }
  }
)
export const getSocialProfiles = createAsyncThunk(
  'appMarketing/getSocialProfiles',
  async ({ search }) => {
    const response = await axios.get(
      `${BASE_URL}/marketing/social-profile?${search ? `&${search}` : ''}`
    )
    return { data: response.data.data, total: response.data.pageCount }
  }
)
export const getEmails = createAsyncThunk('appMarketing/getEmails', async ({ search }) => {
  const response = await axios.get(`${BASE_URL}/marketing/email?${search ? `&${search}` : ''}`)
  return { data: response.data.data, total: response.data.pageCount }
})
export const addAd = createAsyncThunk(
  'appMarketing/addAd',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/ad`, data)
      await dispatch(
        getAds({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addBlogs = createAsyncThunk(
  'appMarketing/addBlogs',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/blog`, data)
      await dispatch(
        getBlogs({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addOtherLink = createAsyncThunk(
  'appMarketing/addOtherLink',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/other-link`, data)
      await dispatch(
        getOtherLinks({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addBDTemplate = createAsyncThunk(
  'appMarketing/addBDTemplate',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/bd-template`, data)
      await dispatch(getBDTemplates())
      await dispatch(
        getBDTemplates({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addProjectTechnology = createAsyncThunk(
  'appMarketing/addProjectTechnology',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/project-technology`, data)
      await dispatch(
        getProjectTechnologies({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addEvent = createAsyncThunk(
  'appMarketing/addEvent',
  async ({ data, page, size, search }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/event`, data)
      await dispatch(
        getEvents({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addUIUX = createAsyncThunk(
  'appMarketing/addUIUX',
  async ({ data, page, size, search }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/uiux`, data)
      await dispatch(
        getUIUX({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addFreelanceProfile = createAsyncThunk(
  'appMarketing/addFreelanceProfile',
  async ({ data, page, size, filterValue }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/freelance-profile`, data)
      await dispatch(
        getFreelanceProfiles({
          page,
          size,
          search: filterValue ? `filter=[{"platform":"${filterValue}"}]` : ''
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addPortfolioProfile = createAsyncThunk(
  'appMarketing/addPortfolioProfile',
  async ({ data, page, size, search }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/portfolio-profile`, data)
      await dispatch(
        getPortfolioProfiles({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addSocialProfile = createAsyncThunk(
  'appMarketing/addSocialProfile',
  async ({ data, page, size, search }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/social-profile`, data)
      await dispatch(
        getSocialProfiles({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addEmail = createAsyncThunk(
  'appMarketing/addEmail',
  async ({ data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/marketing/email`, data)
      await dispatch(
        getEmails({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateAd = createAsyncThunk(
  'appMarketing/updateAd',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/ad/${id}`, data)
      await dispatch(getAds())
      await dispatch(
        getAds({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateBlogs = createAsyncThunk(
  'appMarketing/updateBlogs',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/blog/${id}`, data)
      await dispatch(
        getBlogs({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateProjectTechnology = createAsyncThunk(
  'appMarketing/updateProjectTechnology',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/project-technology/${id}`, data)
      await dispatch(
        getProjectTechnologies({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateOtherLink = createAsyncThunk(
  'appMarketing/updateOtherLink',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/other-link/${id}`, data)
      await dispatch(
        getOtherLinks({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateBDTemplate = createAsyncThunk(
  'appMarketing/updateBDTemplate',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/bd-template/${id}`, data)
      await dispatch(getBDTemplates())
      await dispatch(
        getBDTemplates({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateEvent = createAsyncThunk(
  'appMarketing/updateEvent',
  async ({ id, data, page, size, search }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/event/${id}`, data)
      await dispatch(getEvents())
      await dispatch(
        getEvents({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateUIUX = createAsyncThunk(
  'appMarketing/updateUIUX',
  async ({ id, data, page, size, search }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/uiux/${id}`, data)
      await dispatch(getUIUX())
      await dispatch(
        getUIUX({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateFreelanceProfile = createAsyncThunk(
  'appMarketing/updateFreelanceProfile',
  async ({ id, data, page, size, filterValue }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/freelance-profile/${id}`, data)
      await dispatch(getFreelanceProfiles())
      await dispatch(
        getFreelanceProfiles({
          page,
          size,
          search: filterValue ? `filter=[{"platform":"${filterValue}"}]` : ''
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatePortfolioProfile = createAsyncThunk(
  'appMarketing/updatePortfolioProfile',
  async ({ id, data, page, size, search }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/portfolio-profile/${id}`, data)
      await dispatch(getPortfolioProfiles())
      await dispatch(
        getPortfolioProfiles({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateSocialProfile = createAsyncThunk(
  'appMarketing/updateSocialProfile',
  async ({ id, data, page, size, search }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/social-profile/${id}`, data)
      await dispatch(
        getSocialProfiles({
          page,
          size,
          search
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateEmail = createAsyncThunk(
  'appMarketing/updateEmail',
  async ({ id, data, page, size, startingDate, endingDate }, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/email/${id}`, data)
      await dispatch(
        getEmails({
          page,
          size,
          search:
            startingDate &&
            endingDate &&
            `dateFilter={"startingDate":"${startingDate}","endingDate":"${endingDate}"}`
        })
      )
      return data
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deleteAd = createAsyncThunk('appMarketing/deleteAd', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/ad/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const deleteBlogs = createAsyncThunk('appMarketing/deleteBlogs', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/blog/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const deleteProjectTechnology = createAsyncThunk(
  'appMarketing/deleteProjectTechnology',
  async (payload) => {
    try {
      await axios.delete(`${BASE_URL}/marketing/project-technology/${payload.id}`)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deleteOtherLink = createAsyncThunk('appMarketing/deleteOtherLink', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/other-link/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const deleteBDTemplate = createAsyncThunk(
  'appMarketing/deleteBDTemplate',
  async (payload) => {
    try {
      await axios.delete(`${BASE_URL}/marketing/bd-template/${payload.id}`)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deleteEvent = createAsyncThunk('appMarketing/deleteEvent', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/event/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const deleteUIUX = createAsyncThunk('appMarketing/deleteUIUX', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/uiux/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const deleteFreelanceProfile = createAsyncThunk(
  'appMarketing/deleteFreelanceProfile',
  async (payload) => {
    try {
      await axios.delete(`${BASE_URL}/marketing/freelance-profile/${payload.id}`)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deletePortfolioProfile = createAsyncThunk(
  'appMarketing/deletePortfolioProfile',
  async (payload) => {
    try {
      await axios.delete(`${BASE_URL}/marketing/portfolio-profile/${payload.id}`)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deleteSocialProfile = createAsyncThunk(
  'appMarketing/deleteSocialProfile',
  async (payload) => {
    try {
      await axios.delete(`${BASE_URL}/marketing/social-profile/${payload.id}`)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const deleteEmail = createAsyncThunk('appMarketing/deleteEmail', async (payload) => {
  try {
    await axios.delete(`${BASE_URL}/marketing/email/${payload.id}`)
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error || error?.response?.message || error?.message || 'Internal error'
    )
  }
})
export const givePermissions = createAsyncThunk('appMarketing/givePermissions', async (payload) => {
  try {
    await axios.post(
      `${BASE_URL}/marketing/${payload.route}/permission/${payload.id}`,
      payload.data
    )
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const givePermissionsToPm = createAsyncThunk(
  'appMarketing/givePermissions',
  async (payload) => {
    try {
      await axios.post(`${BASE_URL}/${payload.route}/permission/${payload.id}`, payload.data)
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const readEmail = createAsyncThunk(
  'appMarketing/givePermissions',
  async (id, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/marketing/email/read/${id}`)
      await dispatch(getEmails())
      return id
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const appMarketingSlice = createSlice({
  name: 'appMarketing',
  initialState: {
    ads: { list: [], total: 1 },
    emails: { list: [], total: 1 },
    leads: { list: [], total: 1 },
    events: { list: [], total: 1 },
    blogs: { list: [], total: 1 },
    freelanceProfiles: { list: [], total: 1 },
    portfolioProfiles: { list: [], total: 1 },
    socialProfiles: { list: [], total: 1 },
    projectTechnologies: { list: [], total: 1 },
    otherLinks: { list: [], total: 1 },
    bdTemplates: { list: [], total: 1 },
    uiuxs: { list: [], total: 1 }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAds.fulfilled, (state, action) => {
        state.ads.list = [...action.payload.data]
        state.ads.total = action.payload.total
      })
      .addCase(getEmails.fulfilled, (state, action) => {
        state.emails.list = [...action.payload.data]
        state.emails.total = action.payload.total
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.emails.list = [...action.payload.data]
        state.emails.total = action.payload.total
      })
      .addCase(getProjectTechnologies.fulfilled, (state, action) => {
        state.projectTechnologies.list = [...action.payload.data]
        state.projectTechnologies.total = action.payload.total
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events.list = [...action.payload.data]
        state.events.total = action.payload.total
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.blogs.list = [...action.payload.data]
        state.blogs.total = action.payload.total
      })
      .addCase(getFreelanceProfiles.fulfilled, (state, action) => {
        state.freelanceProfiles.list = [...action.payload.data]
        state.freelanceProfiles.total = action.payload.total
      })
      .addCase(getPortfolioProfiles.fulfilled, (state, action) => {
        state.portfolioProfiles.list = [...action.payload.data]
        state.portfolioProfiles.total = action.payload.total
      })
      .addCase(getSocialProfiles.fulfilled, (state, action) => {
        state.socialProfiles.list = [...action.payload.data]
        state.socialProfiles.total = action.payload.total
      })
      .addCase(getUIUX.fulfilled, (state, action) => {
        state.uiuxs.list = [...action.payload.data]
        state.uiuxs.total = action.payload.total
      })
      .addCase(getOtherLinks.fulfilled, (state, action) => {
        state.otherLinks.list = [...action.payload.data]
        state.otherLinks.total = action.payload.total
      })
      .addCase(getBDTemplates.fulfilled, (state, action) => {
        state.bdTemplates.list = [...action.payload.data]
        state.bdTemplates.total = action.payload.total
      })
  }
})

export default appMarketingSlice.reducer
