// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appTechnology/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/petty-cash?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data.map((currnt) => ({ ...currnt })),
      totalPages: response.data.pageCount,
      availablePettyCash: response.data.availablePettyCash
    }
  }
)

export const getUser = createAsyncThunk('appPettyCash/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appPettyCash/addUser', async (payload, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/petty-cash`, payload.data)
    dispatch(getAllData({ size: payload.size, page: payload.page }))
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk(
  'appPettyCash/updateUser',
  async (user, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/petty-cash/${user.id}`, user.data)
      // await dispatch(getData(getState().users.params))
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteUser = createAsyncThunk('appPettyCash/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/petty-cash/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})

export const appPettyCashSlice = createSlice({
  name: 'appPettyCash',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.availablePettyCash = action.payload.availablePettyCash
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appPettyCashSlice.reducer
