// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appClient/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/client?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount
    }
  }
)
export const getAllStaffs = createAsyncThunk('appClient/getAllStaffs', async () => {
  const response = await axios.get(`${BASE_URL}/staff`)

  return response.data.data
})

export const getAllProjects = createAsyncThunk('appClient/getAllProjects', async () => {
  const response = await axios.get(`${BASE_URL}/project/raw`)
  return response.data
})

export const getData = createAsyncThunk('appClient/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appClient/getUser', async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/client/${id}`)
    return { ...response.data, unauthorized: false }
  } catch (error) {
    if (error.response) {
      console.log(error.response.statusText === '"Forbidden"')
      return { unauthorized: true }
    }
  }
})
export const getProjects = createAsyncThunk('appClient/getProjects', async (id) => {
  const response = await axios.get(`${BASE_URL}/project/project-by-client/${id}`)
  return response.data
})
export const getInvoices = createAsyncThunk('appClient/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-client/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appClient/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-client/${id}`)
  return response.data
})

export const getClientCommentByClientId = createAsyncThunk(
  'appClient/getClientCommentByClientId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/client-comment/by-client/${id}`)
    return response.data
  }
)

export const addComment = createAsyncThunk('appClient/addComment', async (user) => {
  let response
  try {
    response = await axios.post(`${BASE_URL}/client-comment`, user.data)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateComment = createAsyncThunk('appClient/updateComment', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/client-comment/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})
export const deleteComment = createAsyncThunk('appClient/deleteComment', async (id) => {
  await axios.delete(`${BASE_URL}/client-comment/${id}`)
  return id
})

export const addUser = createAsyncThunk('appClient/addUser', async (payload, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/client`, payload.data)
    dispatch(getAllData({ size: payload.size, page: payload.page }))
    return payload
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk(
  'appClient/updateUser',
  async (payload, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/client/${payload.id}`, payload.data)
      dispatch(getAllData({ size: payload.size, page: payload.page }))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addProject = createAsyncThunk(
  'appClient/addProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.post(`${BASE_URL}/project`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedProject = createAsyncThunk(
  'appClient/updatedProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.patch(`${BASE_URL}/project/${user.id}`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteProject = createAsyncThunk(
  'appClient/deleteProject',
  async (id, { dispatch, getState }) => {
    const state = getState()
    await axios.delete(`${BASE_URL}/project/${id}`)
    await dispatch(getProjects(state.client.selectedUser.id))
    return id
  }
)
export const deleteUser = createAsyncThunk(
  'appClient/deleteUser',
  async (payload, { dispatch }) => {
    await axios.delete(`${BASE_URL}/client/${payload.id}`)
    await dispatch(getAllData({ size: payload.size, page: payload.page }))
    return payload
  }
)

export const appClientSlice = createSlice({
  name: 'appClient',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allStaffs: [],
    allProjects: [],
    selectedUser: { projects: [], invoices: [], payments: [], clientComments: [] }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getClientCommentByClientId.fulfilled, (state, action) => {
        state.selectedUser.clientComments = action.payload
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.selectedUser.projects = action.payload
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getAllStaffs.fulfilled, (state, action) => {
        state.allStaffs = action.payload
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.allProjects = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.selectedUser.clientComments.unshift(action.payload)
      })
  }
})

export default appClientSlice.reducer
