// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appPurchaseOrder/getAllData',
  async ({ page, size, role, search, dateFilter }) => {
    const response = await axios.get(
      `${BASE_URL}/purchase-order?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}${dateFilter ? `&${dateFilter}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount
    }
  }
)

export const getData = createAsyncThunk('appPurchaseOrder/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: 1
  }
})

export const getPurchaseOrder = createAsyncThunk(
  'appPurchaseOrder/getPurchaseOrder',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/purchase-order/${id}`)
    return response.data
  }
)
export const getStatus = createAsyncThunk('appPurchaseOrder/getStatus', async () => {
  const response = await axios.get(`${BASE_URL}/purchase-order/status`)
  return response.data
})
export const getPettyCashAmount = createAsyncThunk(
  'appPurchaseOrder/getPettyCashAmount',
  async () => {
    const response = await axios.get(`${BASE_URL}/petty-cash/amount`)
    return response.data
  }
)

export const getUser = createAsyncThunk('appPurchaseOrder/getUser', async (id) => {
  const response = await axios.get(`${BASE_URL}/client/${id}`)
  return response.data
})
export const getClientById = createAsyncThunk('appPurchaseOrder/getClientById', async (id) => {
  const response = await axios.get(`${BASE_URL}/client/${id}`)
  return response.data
})

export const getInvoices = createAsyncThunk('appPurchaseOrder/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-project/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appPurchaseOrder/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-project/${id}`)
  return response.data
})
export const getPaymentsByInvoice = createAsyncThunk(
  'appPurchaseOrder/getPaymentsByInvoice',
  async ({ id }) => {
    const response = await axios.get(`${BASE_URL}/payment/payment-by-invoice/${id}`)
    return response.data.data
  }
)

export const getAllExpenseHead = createAsyncThunk(
  'appPurchaseOrder/getAllExpenseHead',
  async () => {
    const response = await axios.get(`${BASE_URL}/expense-head`)

    return response.data
  }
)

export const addUser = createAsyncThunk('appPurchaseOrder/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/client`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const getPurchaseOrderCommentByPurchaseOrderId = createAsyncThunk(
  'appClient/getPurchaseOrderCommentByPurchaseOrderId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/purchase-order-comment/by-po/${id}`)
    return response.data
  }
)

export const addComment = createAsyncThunk('appClient/addComment', async (user) => {
  let response
  try {
    response = await axios.post(`${BASE_URL}/purchase-order-comment`, user.data)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const updateComment = createAsyncThunk('appClient/updateComment', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/purchase-order-comment/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})

export const addPaymentDetail = createAsyncThunk('appPurchaseOrder/addUser', async (user) => {
  try {
    await axios.post(`${BASE_URL}/payment-detail`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const getPaymentDetail = createAsyncThunk('appPurchaseOrder/getPaymentDetail', async () => {
  try {
    const res = await axios.get(`${BASE_URL}/payment-detail`)
    return res.data.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk(
  'appPurchaseOrder/updateUser',
  async (user, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/client/${user.id}`, user.data)
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addProject = createAsyncThunk(
  'appPurchaseOrder/addProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.post(`${BASE_URL}/project`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedProject = createAsyncThunk(
  'appPurchaseOrder/updatedProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.patch(`${BASE_URL}/project/${user.id}`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteProject = createAsyncThunk(
  'appPurchaseOrder/deleteProject',
  async (id, { dispatch, getState }) => {
    const state = getState()
    await axios.delete(`${BASE_URL}/project/${id}`)
    await dispatch(getProjects(state.client.selectedUser.id))
    return id
  }
)
export const deleteUser = createAsyncThunk(
  'appPurchaseOrder/deleteUser',
  async (id, { dispatch }) => {
    await axios.delete(`${BASE_URL}/client/${id}`)
    // await dispatch(getData(getState().users.params))
    await dispatch(getAllData({ size: 10, page: 1 }))
    return id
  }
)

export const appPurchaseOrderSlice = createSlice({
  name: 'appPurchaseOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    invoice: { payments: [] },
    selectedUser: {
      project: {},
      invoices: [],
      payments: { allData: [] },
      purchaseOrderComments: []
    },
    status: []
  },
  reducers: {
    updatePurchaseOrder(state, action) {
      state.purchaseOrder = { ...state.purchaseOrder, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getPurchaseOrder.fulfilled, (state, action) => {
        state.purchaseOrder = action.payload
      })
      .addCase(getPaymentsByInvoice.fulfilled, (state, action) => {
        state.invoice.payments = action.payload
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.invoice.project.client = action.payload
      })
      .addCase(getAllExpenseHead.fulfilled, (state, action) => {
        state.allExpenseHead = action.payload
      })
      .addCase(getPaymentDetail.fulfilled, (state, action) => {
        state.paymentDetail = action.payload
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.status = action.payload
      })
      .addCase(getPettyCashAmount.fulfilled, (state, action) => {
        state.pettyCashAmount = action.payload
      })
      .addCase(getPurchaseOrderCommentByPurchaseOrderId.fulfilled, (state, action) => {
        state.selectedUser.purchaseOrderComments = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.selectedUser.purchaseOrderComments.unshift(action.payload)
      })
  }
})

export const { updatePurchaseOrder } = appPurchaseOrderSlice.actions
export default appPurchaseOrderSlice.reducer
