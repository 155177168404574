// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appClient/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/project?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount,
      totalProjects: response?.data?.totalProjects,
      amountExpected: response?.data?.amountExpected
    }
  }
)

export const getData = createAsyncThunk('appClient/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appClient/getUser', async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/project/${id}`)
    return { ...response.data, unauthorized: false }
  } catch (error) {
    if (error.response) {
      console.log(error.response.statusText === '"Forbidden"')
      return { unauthorized: true }
    }
  }
})

export const getInvoices = createAsyncThunk('appClient/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-project/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appClient/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-project/${id}`)
  return response.data
})
export const getStaffs = createAsyncThunk('appClient/getStaffs', async (id) => {
  const response = await axios.get(`${BASE_URL}/working-hours/by-project/${id}`)
  return response.data
})
export const getCommissions = createAsyncThunk('appClient/getCommissions', async (id) => {
  const response = await axios.get(`${BASE_URL}/commission/by-project/${id}`)
  return response.data
})
export const getClosingsByProjectId = createAsyncThunk(
  'appClient/getClosingsByProjectId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/commission/distribution-by-project/${id}`)
    return response.data
  }
)
export const getProjectAttributeByProjectId = createAsyncThunk(
  'appClient/getProjectAttributeByProjectId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/project-attribute/by-project/${id}`)
    return response.data
  }
)
export const getProjectCommentByProjectId = createAsyncThunk(
  'appClient/getProjectCommentByProjectId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/project-comment/by-project/${id}`)
    return response.data
  }
)
export const getAllStaffs = createAsyncThunk('appClient/getAllStaffs', async () => {
  const response = await axios.get(`${BASE_URL}/staff/active`)
  return response.data
})
export const getStaffsByProject = createAsyncThunk('appClient/getStaffsByProject', async (id) => {
  const response = await axios.get(`${BASE_URL}/staff/by-project/${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appClient/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/client`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addWorkinghours = createAsyncThunk('appClient/addWorkinghours', async (user) => {
  try {
    await axios.post(`${BASE_URL}/working-hours`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addCommission = createAsyncThunk('appClient/addCommission', async (user) => {
  try {
    await axios.post(`${BASE_URL}/commission`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addAttribute = createAsyncThunk('appClient/addAttribute', async (user) => {
  try {
    await axios.post(`${BASE_URL}/project-attribute`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addComment = createAsyncThunk('appClient/addComment', async (user) => {
  let response
  try {
    response = await axios.post(`${BASE_URL}/project-comment`, user.data)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addExpectedPayment = createAsyncThunk('appClient/addExpectedPayment', async (user) => {
  let response
  try {
    response = await axios.post(`${BASE_URL}/project/expected-payment`, user.data)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addTeam = createAsyncThunk('appClient/addTeam', async (user) => {
  try {
    await axios.post(`${BASE_URL}/project/add-staff/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})
export const updateTeam = createAsyncThunk('appClient/updateTeam', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/project/add-staff/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateWorkinghours = createAsyncThunk('appClient/updateWorkinghours', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/working-hours/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateCommission = createAsyncThunk('appClient/updateCommission', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/commission/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})
export const updateAttribute = createAsyncThunk('appClient/updateAttribute', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/project-attribute/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})
export const updateComment = createAsyncThunk('appClient/updateComment', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/project-comment/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})
export const updateUser = createAsyncThunk('appClient/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/client/${user.id}`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addProject = createAsyncThunk(
  'appClient/addProject',
  async (payload, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/project`, payload.data)
      dispatch(getAllData({ size: payload.size, page: payload.page, search: payload.search }))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedProject = createAsyncThunk(
  'appClient/updatedProject',
  async (payload, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/project/${payload.id}`, payload.data)
      dispatch(getAllData({ size: payload.size, page: payload.page, search: payload.search }))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteProject = createAsyncThunk(
  'appClient/deleteProject',
  async (payload, { dispatch }) => {
    await axios.delete(`${BASE_URL}/project/${payload.id}`)
    dispatch(getAllData({ size: payload.size, page: payload.page, search: payload.search }))
    return payload
  }
)
export const deleteLoggedHours = createAsyncThunk('appClient/deleteProject', async (id) => {
  await axios.delete(`${BASE_URL}/working-hours/${id}`)
  return id
})
export const deleteAttribute = createAsyncThunk('appClient/deleteProject', async (id) => {
  await axios.delete(`${BASE_URL}/project-attribute/${id}`)
  return id
})
export const deleteComment = createAsyncThunk('appClient/deleteComment', async (id) => {
  await axios.delete(`${BASE_URL}/project-comment/${id}`)
  return id
})
export const deleteCommission = createAsyncThunk('appClient/deleteCommission', async (id) => {
  try {
    await axios.delete(`${BASE_URL}/commission/${id}`)
  } catch (error) {
    throw new Error(error.response.data.message)
  }
  return id
})
export const deleteUser = createAsyncThunk('appClient/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/client/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})
export const deleteTeam = createAsyncThunk('appClient/deleteTeam', async (payload) => {
  await axios.post(`${BASE_URL}/project/delete-staff/${payload.id}`, {
    projectId: payload.projectId
  })
})

export const appClientSlice = createSlice({
  name: 'appClient',
  initialState: {
    data: [],
    total: 1,
    totalCount: 0,
    amountExpected: 0,
    params: {},
    allData: [],
    selectedUser: {
      project: {},
      invoices: [],
      payments: [],
      projectAttributes: [],
      projectComments: []
    },
    allStaffs: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.totalCount = action.payload.totalProjects
        state.amountExpected = action.payload.amountExpected
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getStaffs.fulfilled, (state, action) => {
        state.selectedUser.loggedHours = action.payload
      })
      .addCase(getCommissions.fulfilled, (state, action) => {
        state.selectedUser.commissions = action.payload
      })
      .addCase(getClosingsByProjectId.fulfilled, (state, action) => {
        state.selectedUser.closings = action.payload
      })
      .addCase(getProjectAttributeByProjectId.fulfilled, (state, action) => {
        state.selectedUser.projectAttributes = action.payload
      })
      .addCase(getProjectCommentByProjectId.fulfilled, (state, action) => {
        state.selectedUser.projectComments = action.payload
      })
      .addCase(getAllStaffs.fulfilled, (state, action) => {
        state.allStaffs = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.selectedUser.projectComments.unshift(action.payload)
      })
  }
})

export default appClientSlice.reducer
