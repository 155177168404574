// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '@src/views/apps/user/store'
import technology from '@src/views/apps/technology/store'
import stack from '@src/views/apps/stack/store'
import category from '@src/views/apps/category/store'
import question from '@src/views/apps/question/store'
import candidate from '@src/views/apps/candidate/store'
import test from '@src/views/apps/test/store'
import client from '@src/views/apps/client/store'
import project from '@src/views/apps/project/store'
import projectComment from '@src/views/apps/project-comment/store'
import invoice from '@src/views/apps/invoice/store'
import purchaseOrder from '@src/views/apps/purchase-order/store'
import payment from '@src/views/apps/payment/store'
import expense from '@src/views/apps/expense/store'
import bonus from '@src/views/apps/bonus/store'
import staff from '@src/views/apps/staff/store'
import closing from '@src/views/apps/closing/store'
import asset from '@src/views/apps/asset/store'
import marketing from '@src/views/apps/marketing/store'
import pettyCash from '@src/views/apps/petty-cash/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  invoice,
  dataTables,
  permissions,
  technology,
  stack,
  category,
  question,
  candidate,
  test,
  client,
  project,
  payment,
  staff,
  expense,
  bonus,
  closing,
  purchaseOrder,
  pettyCash,
  asset,
  marketing,
  projectComment
}

export default rootReducer
