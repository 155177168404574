// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appCandidate/getAllData',
  async ({ page, size, role, search, webRole }) => {
    if (webRole === 'Interviewer') {
      const response = await axios.get(
        `${BASE_URL}/candidate-interviewer?${
          size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
        }${search ? `&${search}` : ''}`
      )
      return {
        data: response.data.data.map((candte) => ({ ...candte, ...candte.candidate })),
        totalPages: response.data.pageCount
      }
    } else {
      const response = await axios.get(
        `${BASE_URL}/candidate?${
          size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
        }${search ? `&${search}` : ''}`
      )
      return {
        data: response.data.data,
        totalPages: response.data.pageCount
      }
    }
  }
)
export const getInterviewersUser = createAsyncThunk(
  'appCandidate/getInterviewersUser',
  async () => {
    const response = await axios.get(`${BASE_URL}/admin/?filter=role||$eq||Interviewer`)
    return response.data.data
  }
)
export const getUsersCount = createAsyncThunk('appCandidate/getUserCount', async () => {
  const response = await axios.get(`${BASE_URL}/candidate/count`)
  return response.data
})

export const getData = createAsyncThunk('appCandidate/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.fullName,
      role: currnt.role.toLowerCase(),
      email: currnt.email,
      status: 'active',
      avatar: currnt?.image || ''
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appCandidate/getUser', async (id) => {
  const response = await axios.get(`${BASE_URL}/candidate/${id}`)
  return response.data
})

export const getTestById = createAsyncThunk('appCandidate/getTestById', async (id) => {
  const response = await axios.get(`${BASE_URL}/admin-test-question?filter=testId||$eq||${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appCandidate/addUser', async (data, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/candidate`, data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const generateTest = createAsyncThunk(
  'appCandidate/generateTest',
  async (data, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/test/generate/`, data)
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateUser = createAsyncThunk(
  'appCandidate/updateUser',
  async (user, { dispatch }) => {
    try {
      if (user.questionCategoryId & user.technologyId) {
        await axios.patch(`${BASE_URL}/candidate/${user.id}`, user.data)
        dispatch(getAllData({ size: 10, page: 1 }))
        return user
      } else {
        await axios.patch(`${BASE_URL}/candidate/${user.id}`, user.data)
        dispatch(getAllData({ size: 10, page: 1 }))
        return user
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteUser = createAsyncThunk('appCandidate/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/candidate/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})

export const getAllCategory = createAsyncThunk(' appCandidate/getAllCategory', async () => {
  const response = await axios.get(`${BASE_URL}/question-category`)
  return {
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      name: currnt.categoryName
    }))
  }
})
export const changeStatus = createAsyncThunk(
  ' appCandidate/changeStatus',
  async ({ id, status, interviewerIds, testTime, testDate }) => {
    let res
    if (interviewerIds) {
      res = await axios.post(`${BASE_URL}/candidate/change-status/${id}`, {
        interviewerIds,
        status,
        interviewTime: testTime,
        interviewDate: testDate
      })
    } else {
    }
    res = await axios.post(`${BASE_URL}/candidate/change-status/${id}`, {
      status
    })

    return {
      id: res.data.id,
      status: res.data.status
    }
  }
)
export const getAllStatus = createAsyncThunk(' appCandidate/getAllStatus', async () => {
  const response = await axios.get(`${BASE_URL}/candidate/status-list`)
  return response.data
})
export const getAllTechnology = createAsyncThunk(' appCandidate/getAllTechnology', async () => {
  const response = await axios.get(`${BASE_URL}/technology`)
  return {
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      name: currnt.name
    }))
  }
})
export const addComment = createAsyncThunk(' appCandidate/addComment', async (comment) => {
  try {
    const response = await axios.post(`${BASE_URL}/candidate-comment`, comment)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const getAllStacks = createAsyncThunk(' appCandidate/getAllStacks', async () => {
  const response = await axios.get(`${BASE_URL}/tech-stack`)
  return {
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      name: currnt.name
    }))
  }
})

export const appCandidateSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allInterviews: [],
    allStacks: [],
    roleCount: [],
    selectedUser: null,
    testById: {},
    allCategory: [],
    allTechnology: [],
    allStatus: [],
    programmingQuestions: [],
    analyticalQuestions: [],
    behavioralQuestions: [],
    totalCount: 0
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getTestById.fulfilled, (state, action) => {
        const updatedProgrammingQuestions = []
        const updatedBehavioralQuestions = []
        const updatedAnalyticalQuestions = []

        action.payload.forEach((question) => {
          if (question.question.questionCategory.categoryName === 'Programming') {
            updatedProgrammingQuestions.push(question)
          } else if (question.question.questionCategory.categoryName === 'Analytical') {
            updatedAnalyticalQuestions.push(question)
          } else {
            updatedBehavioralQuestions.push(question)
          }
        })
        state.testById = action.payload
        state.programmingQuestions = updatedProgrammingQuestions
        state.analyticalQuestions = updatedAnalyticalQuestions
        state.behavioralQuestions = updatedBehavioralQuestions
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.allCategory = action.payload
      })
      .addCase(getAllTechnology.fulfilled, (state, action) => {
        state.allTechnology = action.payload
      })
      .addCase(getAllStacks.fulfilled, (state, action) => {
        state.allStacks = action.payload.data
      })
      .addCase(getAllStatus.fulfilled, (state, action) => {
        state.allStatus = action.payload
      })
      .addCase(getInterviewersUser.fulfilled, (state, action) => {
        state.allInterviews = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.selectedUser.candidateComments.push(action.payload)
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.allData = state.allData.map((current) => {
          if (current.id === action.payload.id) {
            return { ...current, status: action.payload.status }
          }
          return current
        })
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        // const array = []
        state.roleCount = action.payload.sort((a, b) => b.count - a.count)
        let count = 0
        action.payload.forEach((item) => (count += +item.count))
        state.totalCount = count
      })
  }
})

export default appCandidateSlice.reducer
