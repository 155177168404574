// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  ' appCategory/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/question-category?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data.map((currnt) => ({
        id: currnt.id,
        name: currnt.categoryName,
        technologies: currnt.technologies
      })),
      totalPages: response.data.pageCount
    }
  }
)
export const getUsersCount = createAsyncThunk(' appCategory/getUserCount', async () => {
  const response = await axios.get(`${BASE_URL}/admin/count`)
  return response.data
})

export const getData = createAsyncThunk(' appCategory/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.name,
      technologies: currnt.technologies
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk(' appCategory/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk(' appCategory/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/question-category`, user)
    // await dispatch(getData(getState().users.params))
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk(
  ' appCategory/updateUser',
  async (user, { dispatch }) => {
    try {
      if (user.image) {
        await axios.patch(`${BASE_URL}/admin/profileimage/${user.id}`, user.image)
      }
      await axios.patch(`${BASE_URL}/question-category/${user.id}`, user.data)
      // await dispatch(getData(getState().users.params))
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteUser = createAsyncThunk(' appCategory/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/question-category/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})

export const appCategorySlice = createSlice({
  name: 'appStack',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    roleCount: {
      Admin: 0,
      Hr: 0,
      Specialist: 0,
      Totals: 0
    },
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        action.payload.forEach((element) => {
          if (element.role === 'Hr') {
            state.roleCount.Hr = element.count
          }
          if (element.role === 'Admin') {
            state.roleCount.Admin = element.count
          }
          if (element.role === 'Specialist') {
            state.roleCount.Specialist = element.count
          }
        })

        let total = 0

        for (const current of action.payload) {
          total += Number(current.count)
        }

        state.roleCount.Totals = total
      })
  }
})

export default appCategorySlice.reducer
