// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appStaff/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/staff?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount
    }
  }
)

export const getData = createAsyncThunk('appStaff/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: response?.data?.pageCount
  }
})
export const getUser = createAsyncThunk('appStaff/getUser', async (id) => {
  const response = await axios.get(`${BASE_URL}/staff/${id}`)
  return response.data
})
export const getInitialCommissionByStaff = createAsyncThunk(
  'appStaff/getInitialCommissionByStaff',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/initial-commission/by-staff/${id}`)
    return response.data
  }
)
export const getClosingsByStaffId = createAsyncThunk(
  'appClient/getClosingsByStaffId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/commission/distribution-by-staff/${id}`)
    return response.data
  }
)
export const getProjects = createAsyncThunk('appStaff/getProjects', async (id) => {
  const response = await axios.get(`${BASE_URL}/project/project-by-client/${id}`)
  return response.data
})
export const getInvoices = createAsyncThunk('appStaff/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-client/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appStaff/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-client/${id}`)
  return response.data
})

export const addUser = createAsyncThunk('appStaff/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/staff`, user.data)
    dispatch(getAllData({ size: user.size, page: user.page }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addInitialCommission = createAsyncThunk(
  'appStaff/addInitialCommission',
  async (payload) => {
    let user
    try {
      user = await axios.post(`${BASE_URL}/initial-commission`, payload.data)
      return user
    } catch (error) {
      throw new Error(error.response.data.message)
    }
  }
)
export const updateInitialCommission = createAsyncThunk(
  'appStaff/updateInitialCommission',
  async (payload) => {
    let user
    try {
      user = await axios.patch(`${BASE_URL}/initial-commission/${payload.id}`, payload.data)
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updateUser = createAsyncThunk('appStaff/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/staff/${user.id}`, user.data)
    dispatch(getAllData({ size: user.size, page: user.page }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addProject = createAsyncThunk(
  'appStaff/addProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.post(`${BASE_URL}/project`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const addResignation = createAsyncThunk(
  'appStaff/addResignation',
  async (payload, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/staff/add-resignation/${payload.data.id}`, payload.data.data)
      dispatch(getAllData({ size: payload.size, page: payload.page }))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedProject = createAsyncThunk(
  'appStaff/updatedProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.patch(`${BASE_URL}/project/${user.id}`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteProject = createAsyncThunk(
  'appStaff/deleteProject',
  async (id, { dispatch, getState }) => {
    const state = getState()
    await axios.delete(`${BASE_URL}/project/${id}`)
    await dispatch(getProjects(state.client.selectedUser.id))
    return id
  }
)
export const deleteUser = createAsyncThunk(
  'appClient/deleteUser',
  async ({ data, size, page }, { dispatch }) => {
    await axios.delete(`${BASE_URL}/staff/${data.id}`)
    await dispatch(getAllData({ size, page }))
    return data.id
  }
)
export const getALLCommissionTypes = createAsyncThunk(
  'appClient/getALLCommissionTypes',
  async () => {
    const types = await axios.get(`${BASE_URL}/commission-type`)
    return types.data.data
  }
)

export const getStaffCommentByStaffId = createAsyncThunk(
  'appClient/getStaffCommentByStaffId',
  async (id) => {
    const response = await axios.get(`${BASE_URL}/staff-comment/by-staff/${id}`)
    return response.data
  }
)

export const addComment = createAsyncThunk('appClient/addComment', async (user) => {
  let response
  try {
    response = await axios.post(`${BASE_URL}/staff-comment`, user.data)
    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const updateComment = createAsyncThunk('appClient/updateComment', async (user) => {
  try {
    await axios.patch(`${BASE_URL}/staff-comment/${user.id}`, user.data)
    return user
  } catch (error) {
    throw new Error(error.response.data.message)
  }
})

export const appStaffSlice = createSlice({
  name: 'appStaff',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    commissionTypes: [],
    selectedUser: { projects: [], invoices: [], payments: [], staffComments: [] }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.selectedUser.projects = action.payload
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getALLCommissionTypes.fulfilled, (state, action) => {
        state.commissionTypes = action.payload
      })
      .addCase(getInitialCommissionByStaff.fulfilled, (state, action) => {
        state.selectedUser.initialCommissions = action.payload
      })
      .addCase(getClosingsByStaffId.fulfilled, (state, action) => {
        state.selectedUser.commissionDistributions = action.payload
      })
      .addCase(getStaffCommentByStaffId.fulfilled, (state, action) => {
        state.selectedUser.staffComments = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.selectedUser.staffComments.unshift(action.payload)
      })
  }
})

export default appStaffSlice.reducer
