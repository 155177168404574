// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk('appStack/getAllData', async () => {
  const response = await axios.get(`${BASE_URL}/candidate-test`)
  return { data: response.data }
})
export const getUsersCount = createAsyncThunk('appStack/getUserCount', async () => {
  const response = await axios.get(`${BASE_URL}/admin/count`)
  return { data: response.data }
})

export const getData = createAsyncThunk('appStack/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.name,
      technologies: currnt.technologies
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appStack/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appStack/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/tech-stack`, user.data)
    // await dispatch(getData(getState().users.params))
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk('appStack/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/tech-stack/${user.id}`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const deleteUser = createAsyncThunk('appStack/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/tech-stack/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})
export const saveSelectedOption = createAsyncThunk(
  'appStack/saveSelectedOption',
  async ({ id, selectedOptionId }) => {
    let res
    try {
      res = await axios.patch(`${BASE_URL}/candidate-question/${id}`, {
        selectedOptionId
      })
    } catch (error) {
      throw new Error(error.response)
    }
    return res.data
  }
)
export const submitTest = createAsyncThunk('appStack/submitTest', async () => {
  let res
  try {
    res = await axios.get(`${BASE_URL}/candidate-test/submit`)
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
  return res.data
})

export const appTestSlice = createSlice({
  name: 'appStack',
  initialState: {
    candidateScore: 0,
    isSubmitted: null,
    testTime: null,
    testDate: null,
    allQuestions: [],
    programmingQuestions: [],
    analyticalQuestions: [],
    behavioralQuestions: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allQuestions = action.payload.data.candidateTestQuestions
        state.isSubmitted = action.payload.data.isSubmitted
        state.testTime = action.payload.data.testTime
        state.testDate = action.payload.data.testDate
        state.total = action.payload.totalPages
        action?.payload?.data?.candidateTestQuestions?.forEach((question) => {
          if (question.question.questionCategory.categoryName === 'Programming') {
            state.programmingQuestions.push(question)
          } else if (question.question.questionCategory.categoryName === 'Analytical') {
            state.analyticalQuestions.push(question)
          } else {
            state.behavioralQuestions.push(question)
          }
        })
      })
      .addCase(saveSelectedOption.fulfilled, (state, action) => {
        const updatedQuestion = state.allQuestions.map((qust) => {
          if (qust.id === action.payload.id) {
            qust.selectedOptionId = action.payload.selectedOptionId
          }
          return qust
        })
        const updatedProgrammingQuestions = []
        const updatedBehavioralQuestions = []
        const updatedAnalyticalQuestions = []
        updatedQuestion.forEach((question) => {
          if (question.question.questionCategory.categoryName === 'Programming') {
            updatedProgrammingQuestions.push(question)
          } else if (question.question.questionCategory.categoryName === 'Analytical') {
            updatedAnalyticalQuestions.push(question)
          } else {
            updatedBehavioralQuestions.push(question)
          }
        })
        state.allQuestions = updatedQuestion
        state.programmingQuestions = updatedProgrammingQuestions
        state.analyticalQuestions = updatedAnalyticalQuestions
        state.behavioralQuestions = updatedBehavioralQuestions
      })
      .addCase(submitTest.fulfilled, (state) => {
        state.isSubmitted = true
      })
  }
})

export default appTestSlice.reducer
