// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appClient/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/invoice${!size ? '' : `?limit=${size}&page=${page}${role ? `&${role}` : ''}`}${
        search ? `&${search}` : ''
      }`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount
    }
  }
)

export const getData = createAsyncThunk('appClient/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data,
    totalPages: 1
  }
})

export const getInvoice = createAsyncThunk('appClient/getInvoice', async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/invoice/${id}`)
    return { ...response.data, unauthorized: false }
  } catch (error) {
    if (error.response) {
      console.log(error.response.statusText === '"Forbidden"')
      return { unauthorized: true }
    }
  }
})
export const lastPayment = createAsyncThunk('appClient/lastPayment', async () => {
  const response = await axios.get(`${BASE_URL}/payment/last-payment`)
  return response.data
})

export const getUser = createAsyncThunk('appClient/getUser', async (id) => {
  const response = await axios.get(`${BASE_URL}/client/${id}`)
  return response.data
})
export const getClientById = createAsyncThunk('appClient/getClientById', async (id) => {
  const response = await axios.get(`${BASE_URL}/client/${id}`)
  return response.data
})

export const getInvoices = createAsyncThunk('appClient/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-project/${id}`)
  return response.data
})
export const getPayments = createAsyncThunk('appClient/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-project/${id}`)
  return response.data
})
export const getPaymentsByInvoice = createAsyncThunk(
  'appClient/getPaymentsByInvoice',
  async ({ id }) => {
    const response = await axios.get(`${BASE_URL}/payment/payment-by-invoice/${id}`)
    return response.data.data
  }
)

export const addUser = createAsyncThunk('appClient/addUser', async (user, { dispatch }) => {
  try {
    await axios.post(`${BASE_URL}/client`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addPaymentDetail = createAsyncThunk('appClient/addUser', async (user) => {
  try {
    await axios.post(`${BASE_URL}/payment-detail`, user.data)
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const getPaymentDetail = createAsyncThunk('appClient/getPaymentDetail', async () => {
  try {
    const res = await axios.get(`${BASE_URL}/payment-detail`)
    return res.data.data
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk('appClient/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/client/${user.id}`, user.data)
    dispatch(getAllData({ size: 10, page: 1 }))
    return user
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const addProject = createAsyncThunk(
  'appClient/addProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.post(`${BASE_URL}/project`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedProject = createAsyncThunk(
  'appClient/updatedProject',
  async (user, { dispatch, getState }) => {
    const state = getState()
    try {
      await axios.patch(`${BASE_URL}/project/${user.id}`, user.data)
      dispatch(getProjects(state.client.selectedUser.id))
      return user
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteProject = createAsyncThunk(
  'appClient/deleteProject',
  async (id, { dispatch, getState }) => {
    const state = getState()
    await axios.delete(`${BASE_URL}/project/${id}`)
    await dispatch(getProjects(state.client.selectedUser.id))
    return id
  }
)
export const deleteUser = createAsyncThunk('appClient/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/client/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})

export const appClientSlice = createSlice({
  name: 'appClient',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    invoice: { payments: [] },
    lastPayment: {},
    selectedUser: { project: {}, invoices: [], payments: { allData: [] } },
    paymentDetail: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = { ...state.selectedUser, ...action.payload }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.selectedUser.invoices = action.payload
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.selectedUser.payments = action.payload
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload
      })
      .addCase(getPaymentsByInvoice.fulfilled, (state, action) => {
        state.invoice.payments = action.payload
      })
      .addCase(lastPayment.fulfilled, (state, action) => {
        state.lastPayment = action.payload
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.invoice.project.client = action.payload
      })
      .addCase(getPaymentDetail.fulfilled, (state, action) => {
        state.paymentDetail = action.payload
      })
  }
})

export default appClientSlice.reducer
