// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appAsset/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/asset?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount,
      totalCount: response?.data?.totalCount,
      totalWorth: response?.data?.totalWorth,
      pendingApproval: response?.data?.pendingApproval
    }
  }
)

export const getData = createAsyncThunk('appAsset/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.fullName,
      role: currnt.role.toLowerCase(),
      email: currnt.email,
      status: 'active',
      avatar: currnt?.image || ''
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appAsset/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const getProjects = createAsyncThunk('appClient/getProjects', async (id) => {
  const response = await axios.get(`${BASE_URL}/asset-attribute/by-project/${id}`)
  return response.data
})

export const getAsset = createAsyncThunk('appClient/getAsset', async (id) => {
  const response = await axios.get(`${BASE_URL}/asset/${id}`)
  return response.data
})

export const getAttributes = createAsyncThunk('appClient/getAttributes', async (id) => {
  const response = await axios.get(`${BASE_URL}/asset-attribute/by-project/${id}`)
  return response.data
})

export const getActivities = createAsyncThunk('appClient/getActivities', async (id) => {
  const response = await axios.get(`${BASE_URL}/asset-activity/by-project/${id}`)
  return response.data
})

export const addUser = createAsyncThunk('appAsset/addUser', async (payload, { dispatch }) => {
  let user
  try {
    user = await axios.post(`${BASE_URL}/asset`, payload.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search:
          payload.category && payload.subCategory
            ? `filter=[{"category":"${payload.category}", "subCategory": "${payload.subCategory}"}]`
            : payload.category
            ? `filter=[{"category":"${payload.category}"}]`
            : payload.subCategory
            ? `filter=[{"subCategory":"${payload.subCategory}"}]`
            : ''
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})
export const addActivity = createAsyncThunk(
  'appAsset/addActivity',
  async (payload, { dispatch }) => {
    let res
    try {
      res = await axios.post(`${BASE_URL}/asset-activity`, payload.data)
      await dispatch(getActivities(payload.assetId))
      return res
    } catch (error) {
      throw new Error(error?.response?.data?.message)
    }
  }
)
export const approveActivity = createAsyncThunk(
  'appAsset/addActivity',
  async (payload, { dispatch }) => {
    let res
    try {
      res = await axios.post(`${BASE_URL}/asset-activity/approve/${payload.id}`, payload.data)
      await dispatch(getAsset(payload.assetId))
      await dispatch(getActivities(payload.assetId))
      await dispatch(getAttributes(payload.assetId))
      return res
    } catch (error) {
      throw new Error(error?.response?.data?.message)
    }
  }
)
export const approveDemandActivity = createAsyncThunk(
  'appAsset/addActivity',
  async (payload, { dispatch }) => {
    let res
    try {
      res = await axios.post(`${BASE_URL}/asset-activity/demand-approve/${payload.assetId}`)
      await dispatch(getAsset(payload.assetId))
      await dispatch(getActivities(payload.assetId))
      await dispatch(getAttributes(payload.assetId))
      return res
    } catch (error) {
      throw new Error(error?.response?.data?.message)
    }
  }
)
export const rejectActivity = createAsyncThunk(
  'appAsset/addActivity',
  async (payload, { dispatch }) => {
    let res
    try {
      res = await axios.post(`${BASE_URL}/asset-activity/reject/${payload.id}`, payload.data)
      await dispatch(getAsset(payload.assetId))
      await dispatch(getActivities(payload.assetId))
      await dispatch(getAttributes(payload.assetId))
      return res
    } catch (error) {
      throw new Error(error?.response?.data?.message)
    }
  }
)
export const updateUser = createAsyncThunk('appAsset/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/asset/${user.id}`, user.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search:
          user.category && user.subCategory
            ? `filter=[{"category":"${user.category}", "subCategory": "${user.subCategory}"}]`
            : user.category
            ? `filter=[{"category":"${user.category}"}]`
            : user.subCategory
            ? `filter=[{"subCategory":"${user.subCategory}"}]`
            : ''
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})

export const deleteUser = createAsyncThunk('appAsset/deleteUser', async (payload, { dispatch }) => {
  await axios.delete(`${BASE_URL}/asset/${payload.id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(
    getAllData({
      size: 10,
      page: 1,
      search:
        payload.category && payload.subCategory
          ? `filter=[{"category":"${payload.category}", "subCategory": "${payload.subCategory}"}]`
          : payload.category
          ? `filter=[{"category":"${payload.category}"}]`
          : payload.subCategory
          ? `filter=[{"subCategory":"${payload.subCategory}"}]`
          : ''
    })
  )
  return payload
})
export const addAttribute = createAsyncThunk(
  'appAsset/addAttribute',
  async (payload, { dispatch }) => {
    try {
      await axios.post(`${BASE_URL}/asset-attribute`, payload.data)
      dispatch(getAttributes(payload.assetId))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)
export const updatedAttribute = createAsyncThunk(
  'appAsset/updatedAttribute',
  async (payload, { dispatch }) => {
    try {
      await axios.patch(`${BASE_URL}/asset-attribute/${payload.id}`, payload.data)
      dispatch(getAttributes(payload.assetId))
      return payload
    } catch (error) {
      throw new Error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.message ||
          error?.message ||
          'Internal error'
      )
    }
  }
)

export const deleteAttribute = createAsyncThunk(
  'appAsset/deleteProject',
  async (payload, { dispatch }) => {
    await axios.delete(`${BASE_URL}/asset-attribute/${payload.id}`)
    await dispatch(getAttributes(payload.assetId))
    return payload
  }
)
export const getPayments = createAsyncThunk('appAsset/getPayments', async (id) => {
  const response = await axios.get(`${BASE_URL}/payment/payments-by-client/${id}`)
  return response.data
})
export const getInvoices = createAsyncThunk('appAsset/getInvoices', async (id) => {
  const response = await axios.get(`${BASE_URL}/invoice/invoice-by-client/${id}`)
  return response.data
})

export const appAssetSlice = createSlice({
  name: 'appAsset',
  initialState: {
    data: [],
    total: 1,
    totalCount: 0,
    totalWorth: 0,
    pendingApproval: 0,
    params: {},
    allData: [],
    selectedAsset: {
      projects: [],
      invoices: [],
      payments: [],
      attributes: [],
      pendingApprovalId: null
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.pendingApproval = action.payload.pendingApproval
        state.totalCount = action.payload.totalCount
        state.totalWorth = action.payload.totalWorth
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getAsset.fulfilled, (state, action) => {
        state.selectedAsset = action.payload
      })
      .addCase(getAttributes.fulfilled, (state, action) => {
        state.selectedAsset.attributes = action.payload
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.selectedAsset.activities = action.payload
        const activity = action.payload?.find((element) => element?.approvalStatus === 'Pending')
        if (activity) {
          state.selectedAsset.pendingApprovalId = activity.id
        } else {
          state.selectedAsset.pendingApprovalId = null
        }
      })
      .addCase(getProjects.fulfilled, () => {})
      .addCase(getInvoices.fulfilled, () => {})
      .addCase(getPayments.fulfilled, () => {})
  }
})

export default appAssetSlice.reducer
