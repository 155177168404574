// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appBonus/getAllData',
  async ({ page, size, role, search, closing }) => {
    const response = await axios.get(
      `${BASE_URL}/bonus?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}${closing ? `&${closing}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount,
      totalCount: response?.data?.totalCount,
      totalBonus: response?.data?.totalBonus
    }
  }
)

export const getData = createAsyncThunk('appBonus/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.fullName,
      role: currnt.role.toLowerCase(),
      email: currnt.email,
      status: 'active',
      avatar: currnt?.image || ''
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appBonus/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appBonus/addUser', async (payload, { dispatch }) => {
  let user
  try {
    user = await axios.post(`${BASE_URL}/bonus`, payload.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search: `dateFilter={"startingDate":"${payload.startDatepicker}","endingDate":"${payload.endDatepicker}"}`
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})
export const updateUser = createAsyncThunk('appBonus/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/bonus/${user.id}`, user.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search: `dateFilter={"startingDate":"${user.startDatepicker}","endingDate":"${user.endDatepicker}"}`
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})

export const deleteUser = createAsyncThunk('appBonus/deleteUser', async (payload, { dispatch }) => {
  await axios.delete(`${BASE_URL}/bonus/${payload.id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(
    getAllData({
      size: 10,
      page: 1,
      search: `dateFilter={"startingDate":"${payload.startDatepicker}","endingDate":"${payload.endDatepicker}"}`
    })
  )
  return id
})

export const appBonusSlice = createSlice({
  name: 'appBonus',
  initialState: {
    data: [],
    total: 1,
    totalCount: 0,
    totalExpense: 0,
    params: {},
    allData: [],
    roleCount: {
      Admin: 0,
      Hr: 0,
      Specialist: 0,
      Totals: 0
    },
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.totalBonus = action.payload.totalBonus
        state.totalCount = action.payload.totalCount
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appBonusSlice.reducer
