// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appPayment/getAllData',
  async ({ page, size, role, search, closing }) => {
    const response = await axios.get(
      `${BASE_URL}/expense?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}${closing ? `&${closing}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response?.data?.pageCount,
      totalCount: response?.data?.totalCount,
      expenseByHead: response?.data?.expenseByHead,
      totalExpense: response?.data?.totalExpense
    }
  }
)

export const getData = createAsyncThunk('appPayment/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.fullName,
      role: currnt.role.toLowerCase(),
      email: currnt.email,
      status: 'active',
      avatar: currnt?.image || ''
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appPayment/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const changeStatus = createAsyncThunk('appPayment/changeStatus', async (id) => {
  let response
  try {
    response = await axios.patch(`${BASE_URL}/expense/change-status/${id}`)
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
  return response.data
})

export const addUser = createAsyncThunk('appPayment/addUser', async (payload, { dispatch }) => {
  let user
  try {
    user = await axios.post(`${BASE_URL}/expense`, payload.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search: `dateFilter={"startingDate":"${payload.startDatepicker}","endingDate":"${payload.endDatepicker}"}`
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})
export const updateUser = createAsyncThunk('appPayment/updateUser', async (user, { dispatch }) => {
  try {
    await axios.patch(`${BASE_URL}/expense/${user.id}`, user.data)
    await dispatch(
      getAllData({
        size: 10,
        page: 1,
        search: `dateFilter={"startingDate":"${user.startDatepicker}","endingDate":"${user.endDatepicker}"}`
      })
    )
    return user
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
})

export const deleteUser = createAsyncThunk(
  'appPayment/deleteUser',
  async (payload, { dispatch }) => {
    try {
      await axios.delete(`${BASE_URL}/expense/${payload.id}`)
      await dispatch(
        getAllData({
          size: 10,
          page: 1,
          search: `dateFilter={"startingDate":"${payload.startDatepicker}","endingDate":"${payload.endDatepicker}"}`
        })
      )
    } catch (error) {
      throw new Error(error?.response?.data?.message)
    }
  }
)
export const getAllExpenseHead = createAsyncThunk('appClient/getAllExpenseHead', async () => {
  const response = await axios.get(`${BASE_URL}/expense-head`)

  return response.data
})

export const appPaymentSlice = createSlice({
  name: 'appPayment',
  initialState: {
    data: [],
    total: 1,
    totalCount: 0,
    totalExpense: 0,
    expenseByHead: [],
    params: {},
    allData: [],
    allExpenseHead: [],
    roleCount: {
      Admin: 0,
      Hr: 0,
      Specialist: 0,
      Totals: 0
    },
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
        state.totalExpense = action.payload.totalExpense
        state.totalCount = action.payload.totalCount
        state.expenseByHead = action.payload.expenseByHead
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getAllExpenseHead.fulfilled, (state, action) => {
        state.allExpenseHead = action.payload
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.allData = state.allData.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, status: action.payload.status }
          }
          return item
        })
      })
  }
})

export default appPaymentSlice.reducer
