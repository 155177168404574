// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk('appClient/getAllData', async ({ params }) => {
  const { data } = await axios.get(`${BASE_URL}/project-comment`, { params })
  return {
    data: data.data,
    totalPages: data?.pageCount
  }
})

export const appProjectCommentSlice = createSlice({
  name: 'appClient',
  initialState: {
    allData: [],
    total: 1
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload.data
      state.total = action.payload.totalPages
    })
  }
})

export default appProjectCommentSlice.reducer
