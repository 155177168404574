// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../constants/app.constants'

export const getAllData = createAsyncThunk(
  'appQuestion/getAllData',
  async ({ page, size, role, search }) => {
    const response = await axios.get(
      `${BASE_URL}/question?${
        size === 0 ? '' : `limit=${size}&page=${page}${role ? `&${role}` : ''}`
      }${search ? `&${search}` : ''}`
    )
    return {
      data: response.data.data,
      totalPages: response.data.pageCount
    }
  }
)
export const getUsersCount = createAsyncThunk('appQuestion/getUserCount', async () => {
  const response = await axios.get(`${BASE_URL}/question/count`)
  return response.data
})

export const getData = createAsyncThunk('appQuestion/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      fullName: currnt.fullName,
      role: currnt.role.toLowerCase(),
      email: currnt.email,
      status: 'active',
      avatar: currnt?.image || ''
    })),
    totalPages: 1
  }
})

export const getUser = createAsyncThunk('appQuestion/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appQuestion/addUser', async (user, { dispatch }) => {
  try {
    if (user.technologyId === undefined) {
      await axios.post(
        `${BASE_URL}/question?questionCategoryId=${user.questionCategoryId}`,
        user.data
      )
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } else {
      await axios.post(
        `${BASE_URL}/question?questionCategoryId=${user.questionCategoryId}&technologyId=${user.technologyId}`,
        user.data
      )
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})
export const updateUser = createAsyncThunk('appQuestion/updateUser', async (user, { dispatch }) => {
  try {
    if (user.questionCategoryId & user.technologyId) {
      await axios.patch(`${BASE_URL}/question/update/${user.id}`, user.data)
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    } else {
      await axios.patch(`${BASE_URL}/question/update/${user.id}`, user.data)
      dispatch(getAllData({ size: 10, page: 1 }))
      return user
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        'Internal error'
    )
  }
})

export const deleteUser = createAsyncThunk('appQuestion/deleteUser', async (id, { dispatch }) => {
  await axios.delete(`${BASE_URL}/question/${id}`)
  // await dispatch(getData(getState().users.params))
  await dispatch(getAllData({ size: 10, page: 1 }))
  return id
})

export const getAllCategory = createAsyncThunk(' appCategory/getAllCategory', async () => {
  const response = await axios.get(`${BASE_URL}/question-category`)
  return {
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      name: currnt.categoryName
    }))
  }
})
export const getAllTechnology = createAsyncThunk(' appCategory/getAllTechnology', async () => {
  const response = await axios.get(`${BASE_URL}/technology`)
  return {
    data: response.data.data.map((currnt) => ({
      id: currnt.id,
      name: currnt.name
    }))
  }
})

export const appQuestionSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    roleCount: {
      Programming: 0,
      Analytical: 0,
      Behavioral: 0,
      Totals: 0
    },
    selectedUser: null,
    allCategory: [],
    allTechnology: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.totalPages
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.allCategory = action.payload
      })
      .addCase(getAllTechnology.fulfilled, (state, action) => {
        state.allTechnology = action.payload
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        action.payload.forEach((element) => {
          if (element.category === 'Programming') {
            state.roleCount.Programming = element.count
          }
          if (element.category === 'Behavioral') {
            state.roleCount.Behavioral = element.count
          }
          if (element.category === 'Analytical') {
            state.roleCount.Analytical = element.count
          }
        })

        let total = 0

        for (const current of action.payload) {
          total += Number(current.count)
        }

        state.roleCount.Totals = total
      })
  }
})

export default appQuestionSlice.reducer
